import globalData from "./data.json";
import pos from "./pos.json";

export const formatName = (name) => {
  // 检查是否是英文名
  if (/^[a-zA-Z\s]+$/.test(name)) {
    return name; // 英文名直接返回
  }

  if (name === "Army 陶") {
    return "Army *";
  }

  // 中文名处理
  const length = name.length;

  return name[0] + " " + "* ".repeat(length - 1);
};

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};
export const isMobileSize = () => {
  return window.innerWidth < 1200;
};

export const isWeixin = () => {
  return /MicroMessenger/i.test(navigator.userAgent);
};

export function hideLastTwoDigits(phoneNumber) {
  const str = String(phoneNumber);
  return str.slice(0, str.length - 2) + "**";
}

function getRandom(n, m) {
  var num = Math.floor(Math.random() * (m - n + 1) + n);
  return num;
}

const truncateCityName = (name) => {
  if (!name) {
    return;
  }
  return name.length > 4 ? name.slice(0, 5) + ".." : name;
};

export function getCountrys() {
  const buffers = [];
  for (let i = 0; i < globalData.length; i++) {
    const countryName = globalData[i].country;
    if (buffers.indexOf(countryName) > -1) {
      continue;
    } else {
      buffers.push(countryName);
    }
  }
  return buffers;
}

// getCountrys();
const generateRandomData = () => {
  return Array.from({ length: getCountrys().length }, () =>
    Math.floor(Math.random() * 100 + 300)
  );
};
export function getSeries() {
  return getCountrys().map((name) => [
    {
      name,
      type: "bar",
      stack: "总量",
      label: {
        show: true,
        position: "insideRight",
      },
      data: generateRandomData(),
    },
  ]);
}

export function getCitys() {
  let cityList = [];
  let countries = getCountrys();

  countries.map((name) => {
    const matchData = globalData.filter((item) => item.country === name);
    matchData.forEach((data) => {
      if (data.city) {
        cityList.push({
          countryName: truncateCityName(data.country),
          cityName: truncateCityName(data.city),
          weight: getRandom(15, 20),
        });
      }
    });
  });

  return cityList;
}
// 标注点坐标
export const getPoints = () => {
  const buffer = pos.forEach((item) => {});
};

// 注册的城市
export const checkCity = ["威海", "日照", "成都"];

export const viewport = () => {
  var designWidth = 2640; // 设计稿宽度
  var viewport = document.querySelector("meta[name=viewport]");

  // 定义缩放函数
  function setScale() {
    var scale = 0.5;
    viewport.setAttribute(
      "content",
      "width=" + designWidth + ", initial-scale=" + scale
    );
  }

  // 初次加载时设置缩放比例
  setScale();

  // 监听窗口调整大小事件（包括屏幕旋转）
  window.addEventListener("resize", function () {
    setScale();
  });
};

export const getCityByCountry = (name) => {
  return globalData.filter((item) => item.country === name);
};
export const flagName = {
  CN: "中国",
  US: "美国",
  DE: "德国",
  LK: "斯里兰卡",
  PY: "巴拉圭",
  BR: "巴西",
  AE: "阿联酋",
  SA: "沙特",
  EG: "埃及",
  RU: "俄罗斯",
  KH: "柬埔寨",
  VN: "越南",
  TH: "泰国",
  MM: "缅甸",
  ID: "印尼",
  MY: "马来西亚",
  KE: "肯尼亚",
  UG: "乌干达",
  TZ: "坦桑尼亚",
  ZA: "南非",
  LB: "黎巴嫩",
  KR: "韩国",
  DK: "丹麦",
};
export const getCountryFlagBackgrounds = () => {
  const obj = {};
  Object.keys(flagName).map((name) => {
    obj[name] = {
      width: 15,
      backgroundColor: {
        image: `https://xunfutech.oss-cn-beijing.aliyuncs.com/website/images/flag/${name.toLowerCase()}.png`,
      },
    };
  });
  return obj;
};

export const getFlagPics = () => {
  return Object.keys(flagName).map(
    (name) =>
      `https://xunfutech.oss-cn-beijing.aliyuncs.com/website/images/flag/${name.toLowerCase()}.png`
  );
};
const getScale = () => {
  if (window.innerHeight > 1500) {
    return 1;
  } else if (window.innerHeight > 1000) {
    return 0.8;
  }
  return 0.5;
};

export const ScaleOffset = getScale();

export const autoHeight = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  window.addEventListener("resize", () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const isIOS = () => /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent);

// 高亮显示搜索词
export const highlightKeyword = (text, keyword) => {
  if (!keyword) return text;
  // 对关键字中的特殊字符进行转义
  const escapedKeyword = keyword.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  // 创建正则表达式，使用转义后的关键字
  const regex = new RegExp(escapedKeyword, "gi");
  return text.replace(regex, `<span style="color: #3AB58E;">$&</span>`);
};

// 处理身份，区分主要身份和其他身份
export const resolveJobs = (jobs, isMain) => {
  if (isMain) {
    if (jobs && jobs.length) {
      const mainJobs = jobs.filter((i) => i.isMain);
      if (mainJobs.length) {
        const mainJob = mainJobs[0];
        return mainJob;
      } else return null;
    } else {
      return null;
    }
  } else {
    if (jobs && jobs.length) {
      const elseJobs = jobs.filter((i) => i.isMain == 0);
      return elseJobs;
    } else {
      return [];
    }
  }
};

// 空身份对象-主要身份
const getEmptyMainJob = (data) => {
  const empty = {
    isMain: 1,
    employer: "", //在职/退休为公司
    divisionBusiness: "", //退休/在职--分管业务
    position: "", //在职/退休为职位，自由职业为岗位/职业/身份/技能
    business: [], //行业
    businessString: "",
    capacity: "", //自由职业--能力
    school: "", //学生--学校 对应单位
    major: "", //学生--专业 对应职位
    qualification: "", //学生--学历s
  };
  return { ...empty, ...data };
};

// 空身份对象-其他身份
const getEmptyElseJob = (data) => {
  return {
    ...data,
    isMain: 0,
    employer: "", //在职/退休为公司
    divisionBusiness: "", //退休/在职--分管业务
    position: "", //在职/退休为职位，自由职业为岗位/职业/身份/技能
    business: [], //行业
    businessString: "",
  };
};

// 判断身份是否已填写完毕-其他身份
export const isFullJob = (job) => {
  if (job === null || job === undefined) {
    return false;
  }
  if (typeof job !== "object" || Array.isArray(job)) {
    return false;
  }
  return (
    job.business &&
    job.business.length &&
    job.employer &&
    job.position &&
    job.divisionBusiness
  );
};

// 判断身份是否为空值-主要身份
export const isMainNullJob = (job) => {
  if (job === null || job === undefined) {
    return true;
  }
  if (typeof job !== "object" || Array.isArray(job)) {
    console.error("传入的 job 不是有效的对象", job);
    return true;
  }

  const {
    business,
    employer,
    position,
    divisionBusiness,
    capacity,
    major,
    school,
    qualification,
  } = job;
  const isEmptyValue = (value) =>
    value === null || value === undefined || value === "";
  return (
    isEmptyValue(business) &&
    isEmptyValue(employer) &&
    isEmptyValue(position) &&
    isEmptyValue(divisionBusiness) &&
    isEmptyValue(capacity) &&
    isEmptyValue(major) &&
    isEmptyValue(school) &&
    isEmptyValue(qualification)
  );
};

// 判断身份是否为空值-其他身份
export const isNullJob = (job) => {
  if (job === null || job === undefined) {
    return true;
  }
  if (typeof job !== "object" || Array.isArray(job)) {
    console.error("传入的 job 不是有效的对象", job);
    return true;
  }
  const { business, employer, position, divisionBusiness } = job;
  const isEmptyValue = (value) =>
    value === null || value === undefined || value === "" || value.length == 0;
  return (
    isEmptyValue(business) &&
    isEmptyValue(employer) &&
    isEmptyValue(position) &&
    isEmptyValue(divisionBusiness)
  );
};

// 清空与当前身份无关的属性
export const delJobParams = (theJob, jobStatus) => {
  const job = { ...theJob };
  if (jobStatus < 3) {
    // 在职/退休
    delete job.capacity;
    delete job.school;
    delete job.major;
    delete job.qualification;
  } else if (jobStatus == 3) {
    // 自由职业
    delete job.school;
    delete job.major;
    delete job.qualification;
    delete job.employer;
    delete job.divisionBusiness;
  } else {
    // 学生
    delete job.employer;
    delete job.divisionBusiness;
    delete job.position;
    delete job.capacity;
  }
  delete job.businessString;
  return job;
};

// 将身份中的business处理成字符串businessString
export const resolveBusiness = (data, isMain) => {
  if (isMain == 0 && isNullJob(data)) return getEmptyElseJob(data);
  if (isMain == 1 && isMainNullJob(data)) return getEmptyMainJob(data);
  const newData = { ...data };
  const { business } = data;

  if (!business) {
    newData.businessString = "";
  } else if (Array.isArray(business) && business.length > 0) {
    newData.businessString = business.map((item) => item.name).join("、");
    return newData;
  } else {
    newData.businessString = "";
  }
  return newData;
};

// ai返回行业处理
export const resolveAiBusiness = (res, business) => {
  const result = res.result ? res.result.split(",") : [];
  const industryArray = result.map((i) => {
    return {
      name: i,
      selected: business.some((element) => element.name === i),
    };
  });
  return industryArray;
};

// 提取删除指定索引元素的函数
const removeJobByIndex = (jobs, index) => {
  return jobs.filter((_, jobIndex) => index !== jobIndex + 1);
};

// 其他身份垃圾桶按钮逻辑
export const handleJobDeletion = (jobs, i, firstJob, lastJob) => {
  const showJobs = jobs.filter((job) => job.show);
  const length = jobs.length;
  const showLength = showJobs.length;

  // 处理显示数量小于总数量的情况
  if (showLength < length) {
    if (i === 1 && showLength === 1) {
      return [firstJob];
    }
    return removeJobByIndex(jobs, i);
  }

  // 处理显示数量等于总数量的情况
  if (i === length) {
    if (i === 1) {
      return [firstJob];
    }
    const newJobs = [...jobs];
    newJobs[i - 1] = lastJob;
    return newJobs;
  }

  return removeJobByIndex(jobs, i);
};

// 获取url参数
export const getUrlParams = () => {
  const paramPart = window.location.hash.split("?");
  if (paramPart.length > 1) {
    const hash = paramPart[1];
    const params = {};
    const paramPairs = hash.split("&");
    paramPairs.forEach((pair) => {
      const [key, value] = pair.split("=");
      if (key && value) {
        params[key] = value;
      }
    });
    return params;
  } else {
    return null;
  }
};

// 用户信息有值
export const hasValue = (form) => {
  if (
    form.name ||
    form.cityId ||
    form.userTitleList?.length ||
    form.advantageCards?.length ||
    form.jobs?.length ||
    form.jobStatus > 0
  )
    return true;
  return false;
};

// 表单校验
export const validate = (formData) => {
  // 前置校验：基础字段存在性检查
  if (!formData?.name || !formData?.cityId || !formData?.jobs?.length)
    return false;

  // 主工作校验
  const mainJobs = formData.jobs.filter((job) => job.isMain === 1);
  if (mainJobs.length === 0) return false;
  const mainIdentity = mainJobs[0];
  if (!mainIdentity) return false;

  // 业务状态校验规则映射（策略模式）
  const statusRules = {
    1: ["divisionBusiness", "position", "employer"],
    2: ["divisionBusiness", "position", "employer"],
    3: ["capacity", "position"],
    4: ["school", "qualification", "major"],
  };

  // 获取当前状态对应的校验规则
  const currentStatus = formData.jobStatus ?? 0;
  const requiredFields = statusRules[currentStatus] || [];

  // 动态字段校验
  const isFieldValid = requiredFields.every(
    (field) =>
      mainIdentity[field] !== undefined &&
      mainIdentity[field] !== null &&
      mainIdentity[field] !== ""
  );

  // 最终校验组合
  return isFieldValid && mainIdentity.business?.length > 0;
};

// 输入框聚焦事件
export const handleInputFocus = (e) => {
  isIOS() &&
    e.target &&
    e.target.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
};

// 过滤掉表情包字符
export const filterEmoji = (value) => {
  value = value.trim();
  if (value == "") return value;
  const emojiRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}/gu;
  const filteredValue = value.replace(emojiRegex, "");
  return filteredValue;
};

// 城市组件自动滚动显示选中地区
export const autoCityScroll = (ref) => {
  if (ref.current) {
    // 根据索引找到选中的 <li> 元素
    const selectedLis = ref.current.querySelectorAll(".selected");
    selectedLis.forEach((selectedLi) => {
      if (selectedLi) {
        // 将选中的 <li> 元素滚动到可见区域
        selectedLi.scrollIntoView({
          behavior: "smooth", // 平滑滚动
          block: "center",
        });
      }
    });
  }
};
